import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import styles from './LocationEnvironment.module.scss';
import Header from "../../components/Header/Header";
import MenuBar from "../../components/MenuBar/MenuBar";
import Footer from "../../components/Footer/Footer";
import Bener from "../../components/Bener/Bener";
import FixIcon from "../../components/FixIcon/FixIcon";
import Ready from "../../components/Ready/Ready";
import LocationSectionBox from "../../components/LocationSectionBox/LocationSectionBox";
import { Helmet } from "react-helmet-async";

import section2Image1 from "../../assets/LocationEnvironment/LocationEnvironment1/session2-1.jpg";
import section2Image2 from "../../assets/LocationEnvironment/LocationEnvironment1/session2-2.jpg";
import section2Image3 from "../../assets/LocationEnvironment/LocationEnvironment1/session2-3.jpg";
import section2Image4 from "../../assets/LocationEnvironment/LocationEnvironment1/session2-4.jpg";
import section2Image5 from "../../assets/LocationEnvironment/LocationEnvironment1/session2-5.jpg";
import section2Image6 from "../../assets/LocationEnvironment/LocationEnvironment1/session2-6.jpg";

import page1 from "../../assets/LocationEnvironment/LocationEnvironment1/02cont01_tn.jpg";

const LocationSection = [
	{ img: section2Image1, titleText: "세교신도시 최중심", contentText: "세교3지구(예정)를 맨앞에서<br />누리는 세교지금 최중심 입지<br />" },
	{ img: section2Image2, titleText: "차원이 다른 혁신평면", contentText: "전세대 판상형 · 중소형평형대 구성,<br /> 3면 발코니, 광폭드레스룸 등 <br />, 혁신설계" },
	{ img: section2Image3, titleText: "쾌속한 교통환경 <br />광역으로 통하는 특급 교통", contentText: "서부로,오산역,GTX-C, 분당선연장 등<br />사통팔달 편리한 교통망" },
	{ img: section2Image4, titleText: "학교, 쇼핑,병원, 문화를 더 가깝게 한걸음에<br />모두 갖춘 중심생활", contentText: "예정된 중심상권, 학교, 공원 등 차원이 다른 센트럴 라이프" },
	{ img: section2Image5, titleText: "숲세권 프리미엄 아파트", contentText: "쾌적함이 남다른<br /> 상재봉을 품고사는 유일한 자리 " },
	{ img: section2Image6, titleText: "세교2지구 마지막 프리미엄", contentText: "마지막 민간분양, 분양가 상한제 ,<br /> 적용된 내집 마련 최고의 기회" },
]

const LocationEnvironment1 = () => {
	const menuContents = [{ title: "입지안내", url: "/LocationEnvironment/intro" }, { title: "프리미엄", url: "/LocationEnvironment/primium" }];
	const [isScroll, setIsScroll] = useState(false);
	const { pathname } = useLocation(); // 현재 경로를 가져옴

	useEffect(() => {
		window.scrollTo(0, 0); // 페이지가 로드될 때 스크롤을 최상단으로 이동
	}, [pathname]); // pathname이 변경될 때마다 실행

	// 화면 스크롤이 탑이 아니면 isScroll 값 true로 변환
	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 0) {
				setIsScroll(true);
			} else {
				setIsScroll(false);
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<div className={styles.container}>

				<Helmet>
				<title>오산세교 힐데스하임 - 입지안내</title>
				<meta name="description" content="오산세교 힐데스하임의 우수한 입지 조건과 탁월한 접근성을 자랑합니다. 
				주변의 교육, 쇼핑, 엔터테인먼트 시설과의 근접성이 일상의 편리함을 보장합니다." />
				<meta property="og:title" content="오산세교 힐데스하임 - 입지안내" />
				<meta property="og:description" content="오산세교 힐데스하임의 우수한 입지 조건과 탁월한 접근성을 자랑합니다. 
				주변의 교육, 쇼핑, 엔터테인먼트 시설과의 근접성이 일상의 편리함을 보장합니다." />
				<meta property="og:image" content="https://www.hillstate-thefrist.com/mainImage.jpg" />
				<meta property="og:url" content="https://www.hillstate-thefrist.com/LocationEnvironment/intro" />
				<meta name="twitter:title" content="오산세교 힐데스하임 - 입지안내" />
				<meta name="twitter:description" content="오산세교 힐데스하임의 우수한 입지 조건과 탁월한 접근성을 자랑합니다. 
				주변의 교육, 쇼핑, 엔터테인먼트 시설과의 근접성이 일상의 편리함을 보장합니다." />
				<meta name="twitter:image" content="https://www.hillstate-thefrist.com/mainImage.jpg" />
				<meta name="twitter:url" content="https://www.hillstate-thefrist.com/LocationEnvironment/intro" />
				</Helmet>
			

			<Header isChanged={isScroll} />
			<FixIcon />

			<Bener title="입지환경" />

			<MenuBar contents={menuContents} />

			{/* <h1> 태그를 사용하여 페이지 제목 설정 (SEO 최적화) */}
			<h1 className={styles.screenReaderOnly}>오산세교 힐데스하임 - 입지안내 </h1>
			<p className={styles.screenReaderOnly}>오산세교 힐데스하임의 우수한 입지 조건과 탁월한 접근성을 자랑합니다. 
			주변의 교육, 쇼핑, 엔터테인먼트 시설과의 근접성이 일상의 편리함을 보장합니다.
			</p>

			<div className={styles.textBox}>
				<div>오산 세교지구의 메인으로 사는</div>
				<div>오산세교 힐데스하임의 원탑 라이프의 시작!</div>
				<div>찬란한 비전에 완벽한 주거가치까지 더해 세교를 대표하는 프리미엄 라이프를 시작하다</div>
			</div>

			<img src={page1} className={styles.locationImg} alt="lacation-image-1" />

			<div className={styles.section2}>
				{LocationSection.map((value, idx) => (
					<LocationSectionBox
						image={value.img}
						title={value.titleText}
						text={value.contentText}
					/>
				))}
			</div>

			<div className={styles.commonBox}>
				<div className={styles.notice}>
					※ 상기 기재된 개발계획은 사업주체, 국가기관, 지자체 및 기타 기관의 사업추진 중 변경 및 지연 또는 취소될 수 있으며, 이는 사업주체와 무관함을 알려드립니다.
				</div>
			</div>

			<Footer />
		</div>
	)
}

export default LocationEnvironment1;
