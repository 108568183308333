import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import styles from './SalesInfo.module.scss';
import Header from "../../components/Header/Header";
import MenuBar from "../../components/MenuBar/MenuBar";
import Footer from "../../components/Footer/Footer";
import Bener from "../../components/Bener/Bener";
import Ready from "../../components/Ready/Ready";
import FixIcon from "../../components/FixIcon/FixIcon";
import HelmetCOM from "../../components/HelmetCOM/HelmetCOM";
import { Helmet } from "react-helmet-async";
import page1 from "../../assets/SalesInfo/SalesInfoStampTax/05cont01.jpg"; // 추가: 이미지 경로

const ComplexGuide3 = () => {
    const menuContents = [
        { title: "공급안내", url: "/SalesInfo/guide" },
        { title: "모집공고", url: "/SalesInfo/announcement" },
        { title: "인지세안내", url: "/SalesInfo/stampTax" },
        { title: "당첨자구비서류", url: "/BusinessGuide/documents" },
        { title: "이동통신협의결과서", url: "/SalesInfo/SubscriptionGuide" },
    ];
    const [isScroll, setIsScroll] = useState(false);
    const [isImage2Loaded, setIsImage2Loaded] = useState(false); // 추가: 이미지 로드 상태
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0); // 페이지가 로드될 때 스크롤을 최상단으로 이동
    }, [pathname]);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsScroll(true);
            } else {
                setIsScroll(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleImageLoad = () => {
        setIsImage2Loaded(true); // 이미지 로드 시 상태 업데이트
    };

    return (
        <div className={styles.container}>

		<Helmet>
		<title>오산세교 힐데스하임 - 인지세안내</title>
		<meta name="description" content="오산세교 힐데스하임의 인지세납부 금액과 방법을 확인하세요 
		이러한 정보들을을 제공하여 성공적인 분양을 도와드립니다" />
		<meta property="og:title" content="오산세교 힐데스하임 - 인지세안내" />
		<meta property="og:description" content="오산세교 힐데스하임의 인지세납부 금액과 방법을 확인하세요 
		이러한 정보들을을 제공하여 성공적인 분양을 도와드립니다" />
		<meta property="og:image" content="https://www.hillstate-thefrist.com/mainImage.jpg" />
		<meta property="og:url" content="https://www.hillstate-thefrist.com/SalesInfo/stampTax" />
		<meta name="twitter:title" content="오산세교 힐데스하임 - 인지세안내" />
		<meta name="twitter:description" content="오산세교 힐데스하임의 인지세납부 금액과 방법을 확인하세요 
		이러한 정보들을을 제공하여 성공적인 분양을 도와드립니다" />
		<meta name="twitter:image" content="https://www.hillstate-thefrist.com/mainImage.jpg" />
		<meta name="twitter:url" content="https://www.hillstate-thefrist.com/SalesInfo/stampTax" />
		</Helmet>
            <Header isChanged={isScroll} />
            <FixIcon />

            <Bener title="인지세납부안내" />

            <MenuBar contents={menuContents} />

			{/* <h1> 태그를 사용하여 페이지 제목 설정 (SEO 최적화) */}
			<h1 className={styles.screenReaderOnly}>오산세교 힐데스하임 - 인지세안내 </h1>
			<p className={styles.screenReaderOnly}>오산세교 힐데스하임의 인지세납부 금액과 방법을 확인하세요 
			이러한 정보들을을 제공하여 성공적인 분양을 도와드립니다
			</p>

            <div className={styles.textBox}>
                <div>대전 도안신도시의 눈부신 가치 위에</div>
                <div>힐스테이트의 새로운 자부심으로 찾아옵니다.</div>
            </div>

            {/* 추가: 이미지 로드 및 상태 처리 */}
            <img
                className={`${styles.image2} ${isImage2Loaded ? styles.showImage2 : ''}`}
                src={page1}
                alt="05cont01-img"
                onLoad={handleImageLoad} // 이미지 로드 이벤트 핸들러
            />

            <Ready />

            <Footer />
        </div>
    );
};

export default ComplexGuide3;
