import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styles from './ComplexGuide.module.scss';
import Header from "../../components/Header/Header";
import MenuBar from "../../components/MenuBar/MenuBar";
import Footer from "../../components/Footer/Footer";
import Bener from "../../components/Bener/Bener";
import FixIcon from "../../components/FixIcon/FixIcon";
import { Helmet } from "react-helmet-async";

import page1 from "../../assets/ComplexGuide/ComplexGuide2/03cont01.jpg";


const ComplexGuide1 = () => {
	const menuContents = [
		{ title: "단지 배치도", url: "/ComplexGuide/intro" },
		{ title: "호수 배치도", url: "/ComplexGuide/detailintro" },
		{ title: "커뮤니티", url: "/ComplexGuide/community" },
	];
	const [isScroll, setIsScroll] = useState(false);
	const [isImage2Loaded, setIsImage2Loaded] = useState(false); // 이미지 로딩 상태 추가
	const { pathname } = useLocation(); // 현재 경로를 가져옴

	// 이미지가 로드되면 호출되는 함수
	const handleImageLoad = () => {
		setIsImage2Loaded(true); // 이미지가 로드되면 상태 업데이트
	};

	useEffect(() => {
		window.scrollTo(0, 0); // 페이지가 로드될 때 스크롤을 최상단으로 이동
	}, [pathname]); // pathname이 변경될 때마다 실행

	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 0) {
				setIsScroll(true);
			} else {
				setIsScroll(false);
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<div className={styles.container}>

      <Helmet>
        <title>오산세교 힐데스하임 - 호수배치도</title>
        <meta name="description" content="오산세교 힐데스하임의 호수배치도를 통해 단지 호수 배치를 확인하여 
                      각 동마다 몇층까지 설계되어있는지를 확인하실 수 있습니다." />
        <meta property="og:title" content="오산세교 힐데스하임 - 호수배치도" />
        <meta property="og:description" content="오산세교 힐데스하임의 호수배치도를 통해 단지 호수 배치를 확인하여 
                      각 동마다 몇층까지 설계되어있는지를 확인하실 수 있습니다." />
        <meta property="og:image" content="https://www.hillstate-thefrist.com/mainImage.jpg" />
        <meta property="og:url" content="https://www.hillstate-thefrist.com/ComplexGuide/detailintro" />
        <meta name="twitter:title" content="오산세교 힐데스하임 - 호수배치도" />
        <meta name="twitter:description" content="오산세교 힐데스하임의 호수배치도를 통해 단지 호수 배치를 확인하여 
                      각 동마다 몇층까지 설계되어있는지를 확인하실 수 있습니다." />
        <meta name="twitter:image" content="https://www.hillstate-thefrist.com/mainImage.jpg" />
        <meta name="twitter:url" content="https://www.hillstate-thefrist.com/ComplexGuide/detailintro" />
        </Helmet>
			<Header isChanged={isScroll} />
			<FixIcon />
			<Bener title="단지안내" />
			<MenuBar contents={menuContents} />

{         /* <h1> 태그를 사용하여 페이지 제목 설정 (SEO 최적화) */}
            <h1 className={styles.screenReaderOnly}>오산세교 힐데스하임 - 호수배치도도</h1>
            <p className={styles.screenReaderOnly}>오산세교 힐데스하임의 호수배치도를 통해 단지 호수 배치를 확인하여 
            각 동마다 몇층까지 설계되어있는지를 확인하실 수 있습니다.
            </p>

			<div className={styles.textBox}>
      <div>오산세교 힐데스하임의 설계되는</div>
      <div>호수배치도를 확인하세요</div>
			</div>

			{/* 이미지에 애니메이션 효과 추가 */}
			<img
				className={`${styles.image2} ${isImage2Loaded ? styles.showImage2 : ''}`}
				src={page1}
				alt="Ohsan Segyo Hildesheim complex layout illustration-Image2"
				onLoad={handleImageLoad}  // 이미지 로드 후 애니메이션 실행
			/>

			<div className={styles.commonBox}>
				<div className={styles.notice}>
					※ 상기 단지배치도 이미지는 소비자의 이해를 돕기 위한 것으로 실제와 차이가 날 수 있습니다
				</div>
			
			</div>

			<Footer />
		</div>
	);
}

export default ComplexGuide1;
