import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import styles from './BusinessGuide.module.scss';
import Header from "../../components/Header/Header";
import MenuBar from "../../components/MenuBar/MenuBar";
import Footer from "../../components/Footer/Footer";
import Bener from "../../components/Bener/Bener";
import Ready from "../../components/Ready/Ready";
import FixIcon from "../../components/FixIcon/FixIcon";
import { Helmet } from "react-helmet-async";

import page1 from "../../assets/BusinessGuide/BusinessGuide2/02cont01.jpg";

const BusinessGuide2 = () => {
	const menuContents = [
		{ title: "사업안내", url: "/BusinessGuide/intro" },
		{ title: "분양일정", url: "/BusinessGuide/plan" },
		{ title: "계약서류안내", url: "/BusinessGuide/documents" }
	];
	const [isScroll, setIsScroll] = useState(false);
	const { pathname } = useLocation(); // 현재 경로를 가져옴

	useEffect(() => {
		window.scrollTo(0, 0); // 페이지가 로드될 때 스크롤을 최상단으로 이동
	}, [pathname]); // pathname이 변경될 때마다 실행

	// 화면 스크롤이 탑이 아니면 isScroll 값 true로 변환
	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 0) {
				setIsScroll(true);
			} else {
				setIsScroll(false);
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<div className={styles.container}>
				<Helmet>
					<title>오산세교 힐데스하임 - 분양일정</title>
					<meta name="description" content="오산세교 힐데스하임의 분양일정과 주요 청약 정보를 확인하세요. 새로운 주거 단지에서 기회를 잡기 위한 모든 필요한 날짜와 절차에 대해 자세히 안내합니다." />
					<meta property="og:title" content="오산세교 힐데스하임 - 분양일정" />
					<meta property="og:description" content="오산세교 힐데스하임의 사업개요를 통해 프로젝트의 전반적인 개요와 개발 비전을 확인하세요. 최신 건축 기술과 혁신적인 설계를 통해 쾌적한 생활 환경을 제공합니다." />
					<meta property="og:image" content="https://www.hillstate-thefrist.com/mainImage.jpg" />
					<meta property="og:url" content="https://www.hillstate-thefrist.com/BusinessGuide/plan" />
					<meta name="twitter:title" content="오산세교 힐데스하임 - 분양일정" />
					<meta name="twitter:description" content="오산세교 힐데스하임의 분양일정과 주요 청약 정보를 확인하세요. 새로운 주거 단지에서 기회를 잡기 위한 모든 필요한 날짜와 절차에 대해 자세히 안내합니다." />
					<meta name="twitter:image" content="https://www.hillstate-thefrist.com/mainImage.jpg" />
					<meta name="twitter:url" content="https://www.hillstate-thefrist.com/BusinessGuide/plan" />
				</Helmet>
			
			
			<Header isChanged={isScroll} />
			<FixIcon />

			<Bener title="사업개요" />

			<MenuBar contents={menuContents} />

							{/* <h1> 태그를 사용하여 페이지 제목 설정 (SEO 최적화) */}
						<h1 className={styles.screenReaderOnly}>오산세교 힐데스하임 - 분양일정</h1>
						<p className={styles.screenReaderOnly}>오산세교 힐데스하임의 분양일정과 주요 청약 정보를 확인하세요. 
							새로운 주거 단지에서 기회를 잡기 위한 모든 필요한 날짜와 절차에 대해 자세히 안내합니다.
						</p>

			<img className={styles.img1} src={page1} alt="오산세교 힐데스하임의 분양일정" />

			<Footer />
		</div>	
	)
}

export default BusinessGuide2;
