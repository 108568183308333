import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import styles from './Interior.module.scss';
import Header from "../../components/Header/Header";
import MenuBar from "../../components/MenuBar/MenuBar";
import Footer from "../../components/Footer/Footer";
import Bener from "../../components/Bener/Bener";
import FixIcon from "../../components/FixIcon/FixIcon";
import Ready from "../../components/Ready/Ready";

import { Helmet } from "react-helmet-async";

const Interior1 = () => {
	const menuContents = [
		{ title: "69A", url: "/Interior/59A" },
		{ title: "72", url: "/Interior/84A" },
		{ title: "84A", url: "/Interior/84B" },

	];
	const [isScroll, setIsScroll] = useState(false);
	const { pathname } = useLocation(); // 현재 경로를 가져옴

	useEffect(() => {
		window.scrollTo(0, 0); // 페이지가 로드될 때 스크롤을 최상단으로 이동
	}, [pathname]); // pathname이 변경될 때마다 실행

	// 화면 스크롤이 탑이 아니면 isScroll 값 true로 변환
	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 0) {
				setIsScroll(true);
			} else {
				setIsScroll(false);
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<div className={styles.container}>

		<Helmet>
		<title>오산세교 힐데스하임 - 인테리어 </title>
		<meta name="description" content="오산세교 힐데스하임의 인테리어를 확인해보세요
										  현대적인 설계와 효율적인 공간 활용을 특징으로 합니다." />
		<meta property="og:title" content="오산세교 힐데스하임 - 인테리어" />
		<meta property="og:description" content="오산세교 힐데스하임의 인테리어를 확인해보세요
										  현대적인 설계와 효율적인 공간 활용을 특징으로 합니다." />
		<meta property="og:image" content="https://www.hillstate-thefrist.com/mainImage.jpg" />
		<meta property="og:url" content="https://www.hillstate-thefrist.com/Interior/59A" />
		<meta name="twitter:title" content="오산세교 힐데스하임 - 인테리어" />
		<meta name="twitter:description" content="오산세교 힐데스하임의 인테리어를 확인해보세요
										  현대적인 설계와 효율적인 공간 활용을 특징으로 합니다." />
		<meta name="twitter:image" content="https://www.hillstate-thefrist.com/mainImage.jpg" />
		<meta name="twitter:url" content="https://www.hillstate-thefrist.com/Interior/59A" />
		</Helmet>

			<Header isChanged={isScroll} />
			<FixIcon />

			<Bener title="인테리어" />

			<MenuBar contents={menuContents} />

				{/* <h1> 태그를 사용하여 페이지 제목 설정 (SEO 최적화) */}
			<h1 className={styles.screenReaderOnly}>오산세교 힐데스하임 - 인테리어</h1>
			<p className={styles.screenReaderOnly}>오산세교 힐데스하임의 인테리어를 확인해보세요 현대적인 설계와 효율적인 공간 활용을 특징으로 합니다.
			</p>

			<Ready />

			<div className={styles.commonBox}>
				<div className={styles.notice}>
					※ 상기 이미지에 표현된 외관 디자인은 개략적인 이해를 돕기 위한 것으로, 상품특화 및 인허가 협의에 따라 입면 디자인, 경관조명, 출입구, 색채, 몰딩, 창호, 난간, 옥상 장식물, 줄눈, 각종 시설물의 디자인 및 형태, 마감사양, 조명 설치 위치 등이 실시공시 변경될 수 있습니다.
				</div>
			
			</div>

			<Footer />
		</div>
	)
}

export default Interior1;
