import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useMediaQuery } from "react-responsive";

import styles from './Brand.module.scss';
import Header from "../../components/Header/Header";
import MenuBar from "../../components/MenuBar/MenuBar";
import Footer from "../../components/Footer/Footer";
import Bener from "../../components/Bener/Bener";
import FixIcon from "../../components/FixIcon/FixIcon";

import page1 from "../../assets/Brand/intro/brand1.jpg";

const Brand1 = () => {
    const menuContents = [{ title: "브랜드소개", url: "/brand/intro" }, { title: "홍보 영상", url: "/brand/video" }];
    const [isScroll, setIsScroll] = useState(false);
    const { pathname } = useLocation(); // 현재 경로를 가져옴

    useEffect(() => {
        window.scrollTo(0, 0); // 페이지가 로드될 때 스크롤을 최상단으로 이동
    }, [pathname]);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsScroll(true);
            } else {
                setIsScroll(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className={styles.container}>
            {/* Helmet을 사용해 메타 데이터 설정 */}
            <Helmet>
                <title>오산세교 힐데스하임 - 브랜드 소개</title>
                <meta name="description" content="오산세교 힐데스하임은 품질과 혁신을 바탕으로 고객에게 최상의 주거 경험을 제공하는 브랜드입니다. 세심한 주거 설계와 고객 중심의 서비스를 통해 평택 브레인시티의 새로운 주거 문화를 선도합니다." />
                <meta property="og:title" content="오산세교 힐데스하임 - 브랜드 소개" />
                <meta property="og:description" content="오산세교 힐데스하임은 혁신을 바탕으로 고객에게 최상의 주거 경험을 제공하는 브랜드입니다. 세심한 주거 설계와 고객 중심의 서비스를 통해 평택 브레인시티의 새로운 주거 문화를 선도합니다." />
                <meta property="og:image" content="https://www.hillstate-thefrist.com/mainImage.jpg" />
                <meta property="og:url" content="https://www.hillstate-thefrist.com/BusinessGuide/intro" />
                <meta name="twitter:title" content="오산세교 힐데스하임 - 브랜드 소개" />
                <meta name="twitter:description" content="오산세교 힐데스하임은 품질과 혁신을 바탕으로 고객에게 최상의 주거 경험을 제공하는 브랜드입니다. 세심한 주거 설계와 고객 중심의 서비스를 통해 평택 브레인시티의 새로운 주거 문화를 선도합니다." />
                <meta name="twitter:image" content="https://www.hillstate-thefrist.com/mainImage.jpg" />
                <meta name="twitter:url" content="https://www.hillstate-thefrist.com/BusinessGuide/intro" />
            </Helmet>

            <Header isChanged={isScroll} />
            <FixIcon />

            {/* Bener 컴포넌트 */}
            <Bener title="브랜드 소개" />

            {/* 메뉴 바 */}
            <MenuBar contents={menuContents} />

            {/* 페이지 내용 */}
            <div className={styles.textBox}>
                <div>오산세교지구의 눈부신 가치 위에</div>
                <div>오산세교 힐데스하임의 새로운 자부심으로 찾아옵니다.</div>
            </div>

            {/* <h1> 태그를 사용하여 페이지 제목 설정 (SEO 최적화) */}
            <h1 className={styles.screenReaderOnly}>오산세교 힐데스하임 - 브랜드 소개</h1>
            <p className={styles.screenReaderOnly}>오산세교 힐데스하임은 품질과 혁신을 바탕으로 고객에게 최상의 주거 경험을 제공하는 브랜드이고
			세심한 주거 설계와 고객 중심의 서비스를 통해 평택 브레인시티의 새로운 주거 문화를 선도합니다</p>
    



            {/* 이미지 */}
            <img src={page1} className={styles.page1Image} alt="오산세교 힐데스하임 brand-image" />

            <Footer />
        </div>
    );
}

export default Brand1;
