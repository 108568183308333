import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styles from './SalesInfo.module.scss';
import Header from "../../components/Header/Header";
import MenuBar from "../../components/MenuBar/MenuBar";
import Footer from "../../components/Footer/Footer";	
import Bener from "../../components/Bener/Bener";
import FixIcon from "../../components/FixIcon/FixIcon";
import { Helmet } from "react-helmet-async";
import page1 from "../../assets/SalesInfo/guide/03cont02.jpg";

const ComplexGuide1 = () => {
    const menuContents = [
        { title: "공급안내", url: "/SalesInfo/guide" },
        { title: "모집공고", url: "/SalesInfo/announcement" },
        { title: "인지세안내", url: "/SalesInfo/stampTax" },
        { title: "당첨자구비서류", url: "/BusinessGuide/documents" },
        { title: "이동통신협의결과서", url: "/SalesInfo/SubscriptionGuide" },
    ];
    const [isScroll, setIsScroll] = useState(false);
    const [isImage2Loaded, setIsImage2Loaded] = useState(false); // 상태 정의
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsScroll(true);
            } else {
                setIsScroll(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleImageLoad = () => {
        setIsImage2Loaded(true); // 이미지 로드 시 상태 업데이트
    };

    return (
        <div className={styles.container}>

		<Helmet>
		<title>오산세교 힐데스하임 - 공급안내</title>
		<meta name="description" content="오산세교 힐데스하임의 공급정보와 분양가를 확인하여 새로운 주거 단지에서 기회를 잡기 위한 모든 필요한 정보와 가격, 절차에 대해 자세히 안내합니다. " />
		<meta property="og:title" content="오산세교 힐데스하임 - 공급안내" />
		<meta property="og:description" content="오산세교 힐데스하임의 공급정보와 분양가를 확인하여 새로운 주거 단지에서 기회를 잡기 위한 모든 필요한 정보와 가격, 절차에 대해 자세히 안내합니다. " />
		<meta property="og:image" content="https://www.hillstate-thefrist.com/mainImage.jpg" />
		<meta property="og:url" content="https://www.hillstate-thefrist.com/SalesInfo/guide" />
		<meta name="twitter:title" content="오산세교 힐데스하임 - 공급안내" />
		<meta name="twitter:description" content="오산세교 힐데스하임의 공급정보와 분양가를 확인하여 새로운 주거 단지에서 기회를 잡기 위한 모든 필요한 정보와 가격, 절차에 대해 자세히 안내합니다." />
		<meta name="twitter:image" content="https://www.hillstate-thefrist.com/mainImage.jpg" />
		<meta name="twitter:url" content="https://www.hillstate-thefrist.com/SalesInfo/guide" />
		</Helmet>

            <Header isChanged={isScroll} />
            <FixIcon />

            <Bener title="청약안내" />

            <MenuBar contents={menuContents} />

			{/* <h1> 태그를 사용하여 페이지 제목 설정 (SEO 최적화) */}
			<h1 className={styles.screenReaderOnly}>오산세교 힐데스하임 - 공급안내 </h1>
			<p className={styles.screenReaderOnly}>오산세교 힐데스하임의 공급정보와 분양가를 확인하여
				새로운 주거 단지에서 기회를 잡기 위한 모든 필요한 정보와 가격, 절차에 대해 자세히 안내합니다.
			</p>

            <div className={styles.textBox}>
                <div>인터넷 청약하는 방법을 확인하세요</div>
                <div>소수만 누리는 프리미엄 힐스테이트 도안리버파크 2차</div>
            </div>

            <img
                className={`${styles.image2} ${isImage2Loaded ? styles.showImage2 : ''}`}
                src={page1}
                alt="03cont02-img"
                onLoad={handleImageLoad} // 이미지 로드 시 호출
            />

            <div className={styles.commonBox2}>
                <div className={styles.notice}>
                    ※ 상기 이미지는 고객의 이해를 돕기 위한 것으로 성공적인 청약을 위해 도움을 드리고있습니다.
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default ComplexGuide1;
