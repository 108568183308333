import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import styles from './FloorPlan.module.scss';
import Header from "../../components/Header/Header";
import MenuBar from "../../components/MenuBar/MenuBar";
import Footer from "../../components/Footer/Footer";
import Bener from "../../components/Bener/Bener";
import FixIcon from "../../components/FixIcon/FixIcon";
import { Helmet } from "react-helmet-async";
import page1 from "../../assets/FloorPlan/FloorPlan1/69a01.jpg";
import page2 from "../../assets/FloorPlan/FloorPlan1/69a.jpg";

const FloorPlan1 = () => {
	const menuContents = [
		{ title: "69A㎡", url: "/FloorPlan/84A" },
		{ title: "69B㎡", url: "/FloorPlan/84B" },
		{ title: "69C㎡", url: "/FloorPlan/84C" },
		{ title: "69D㎡", url: "/FloorPlan/84D" },
		{ title: "72㎡", url: "/FloorPlan/101A" },
		{ title: "84A㎡", url: "/FloorPlan/101B" },
		{ title: "84B㎡", url: "/FloorPlan/101C" },
		{ title: "84C㎡", url: "/FloorPlan/120A" },
		{ title: "84D㎡", url: "/FloorPlan/120B" },
	];
	const [isScroll, setIsScroll] = useState(false);
	const { pathname } = useLocation(); // 현재 경로를 가져옴

	useEffect(() => {
		window.scrollTo(0, 0); // 페이지가 로드될 때 스크롤을 최상단으로 이동
	}, [pathname]); // pathname이 변경될 때마다 실행

	// 화면 스크롤이 탑이 아니면 isScroll 값 true로 변환
	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 0) {
				setIsScroll(true);
			} else {
				setIsScroll(false);
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<div className={styles.container}>

		<Helmet>
		<title>오산세교 힐데스하임 - 평면도 </title>
		<meta name="description" content="오산세교 힐데스하임의 69A타입의 유니트는 현대적인 설계와 효율적인 공간 활용을 특징으로 합니다. 
								각 유닛은 세련된 인테리어와 최신 편의 시설을 갖추고 있어, 최적의 주거 경험을 제공합니다. 
								다양한 평면 옵션을 통해 개인의 라이프스타일과 필요에 맞춰 선택할 수 있습니다.
" />
		<meta property="og:title" content="오산세교 힐데스하임 - 평면도" />
		<meta property="og:description" content="오산세교 힐데스하임의 69A타입의 유니트는 현대적인 설계와 효율적인 공간 활용을 특징으로 합니다. 
								각 유닛은 세련된 인테리어와 최신 편의 시설을 갖추고 있어, 최적의 주거 경험을 제공합니다. 
								다양한 평면 옵션을 통해 개인의 라이프스타일과 필요에 맞춰 선택할 수 있습니다." />
		<meta property="og:image" content="https://www.hillstate-thefrist.com/mainImage.jpg" />
		<meta property="og:url" content="https://www.hillstate-thefrist.com/FloorPlan/84A" />
		<meta name="twitter:title" content="오산세교 힐데스하임 - 평면도" />
		<meta name="twitter:description" content="오산세교 힐데스하임의 69A타입의 유니트는 현대적인 설계와 효율적인 공간 활용을 특징으로 합니다. 
								각 유닛은 세련된 인테리어와 최신 편의 시설을 갖추고 있어, 최적의 주거 경험을 제공합니다. 
								다양한 평면 옵션을 통해 개인의 라이프스타일과 필요에 맞춰 선택할 수 있습니다." />
		<meta name="twitter:image" content="https://www.hillstate-thefrist.com/mainImage.jpg" />
		<meta name="twitter:url" content="https://www.hillstate-thefrist.com/FloorPlan/84A" />
		</Helmet>

			
			

			<Header isChanged={isScroll} />
			<FixIcon />

			<Bener title="세대안내" />

			<MenuBar contents={menuContents} />

			{/* <h1> 태그를 사용하여 페이지 제목 설정 (SEO 최적화) */}
			<h1 className={styles.screenReaderOnly}>오산세교 힐데스하임 - 평면도</h1>
			<p className={styles.screenReaderOnly}>오산세교 힐데스하임의 69A타입의 유니트는 현대적인 설계와 효율적인 공간 활용을 특징으로 합니다. 
											각 유닛은 세련된 인테리어와 최신 편의 시설을 갖추고 있어, 최적의 주거 경험을 제공합니다. 
											다양한 평면 옵션을 통해 개인의 라이프스타일과 필요에 맞춰 선택할 수 있습니다.
			</p>
			<img src={page1} className={styles.page1Image} alt="FloorPlan69a-image-1" />
			<img src={page2} className={styles.page1Image} alt="FloorPlan69a-image-2" />
	

			<div className={styles.commonBox}>
				<div className={styles.notice}>
					※ 평면도는 소비자의 이해를 돕기 위한 것으로 실 시공 시 다소 변경될 수 있으며, 자세한 내용은 반드시 견본주택에서 확인하시기 바랍니다.
				</div>
			</div>

			<Footer />
		</div>
	)
}

export default FloorPlan1;
