import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import styles from './BusinessGuide.module.scss';
import Header from "../../components/Header/Header";
import MenuBar from "../../components/MenuBar/MenuBar";
import Footer from "../../components/Footer/Footer";
import Bener from "../../components/Bener/Bener";
import FixIcon from "../../components/FixIcon/FixIcon";
import Ready from "../../components/Ready/Ready";
import { Helmet } from "react-helmet-async";

import page1 from "../../assets/BusinessGuide/BusinessGuide3/01cont01.jpg";

const BusinessGuide2 = () => {
	const menuContents = [
		{ title: "공급안내", url: "/SalesInfo/guide" },
		{ title: "모집공고", url: "/SalesInfo/announcement" },
		{ title: "인지세안내", url: "/SalesInfo/stampTax" },
		{ title: "당첨자구비서류", url: "/BusinessGuide/documents" },
		{ title: "이동통신협의결과서", url: "/SalesInfo/SubscriptionGuide" },
	];
	const [isScroll, setIsScroll] = useState(false);
	const { pathname } = useLocation(); // 현재 경로를 가져옴

	useEffect(() => {
		window.scrollTo(0, 0); // 페이지가 로드될 때 스크롤을 최상단으로 이동
	}, [pathname]); // pathname이 변경될 때마다 실행

	// 화면 스크롤이 탑이 아니면 isScroll 값 true로 변환
	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 0) {
				setIsScroll(true);
			} else {
				setIsScroll(false);
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<div className={styles.container}>

					<Helmet>
					<title>오산세교 힐데스하임 - 당첨자구비서류</title>
					<meta name="description" content="오산세교 힐데스하임의 분양일정과 주요서류를 확인하세요. 필요한 모든 정보를 제공하여 성공적인 분양을 지원합니다." />
					<meta property="og:title" content="오산세교 힐데스하임 - 당첨자구비서류" />
					<meta property="og:description" content="오산세교 힐데스하임의 분양일정과 주요서류를 확인하세요. 필요한 모든 정보를 제공하여 성공적인 분양을 지원합니다." />
					<meta property="og:image" content="https://www.hillstate-thefrist.com/mainImage.jpg" />
					<meta property="og:url" content="http://localhost:3002/BusinessGuide/documents" />
					<meta name="twitter:title" content="오산세교 힐데스하임 - 당첨자구비서류" />
					<meta name="twitter:description" content="오산오산세교 힐데스하임의 분양일정과 주요서류를 확인하세요. 필요한 모든 정보를 제공하여 성공적인 분양을 지원합니다." />
					<meta name="twitter:image" content="https://www.hillstate-thefrist.com/mainImage.jpg" />
					<meta name="twitter:url" content="http://localhost:3002/BusinessGuide/documents" />
					</Helmet>
			

			<Header isChanged={isScroll} />
			<FixIcon />

			<Bener title="사업개요" />

			<MenuBar contents={menuContents} />

						{/* <h1> 태그를 사용하여 페이지 제목 설정 (SEO 최적화) */}
						<h1 className={styles.screenReaderOnly}>오산세교 힐데스하임 - 당첨자구비서류</h1>
						<p className={styles.screenReaderOnly}>오산세교 힐데스하임의 구비서류안내를 통해 중요한 정보를 확인하세요
							필요한 모든 정보를 제공하여 성공적인 분양을 지원합니다.
						</p>				

			<img className={styles.img2} src={page1} alt="오산세교 힐데스하임의 당첨자서류안내이미지" />

			<Footer />
		</div>
	)
}

export default BusinessGuide2;
