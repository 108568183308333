import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import styles from './LocationEnvironment.module.scss';
import Header from "../../components/Header/Header";
import MenuBar from "../../components/MenuBar/MenuBar";
import Footer from "../../components/Footer/Footer";
import Bener from "../../components/Bener/Bener";
import FixIcon from "../../components/FixIcon/FixIcon";
import { Helmet } from "react-helmet-async";
import Ready from "../../components/Ready/Ready";

import page1 from "../../assets/LocationEnvironment/LocationEnvironment2/03cont01.jpg";

const LocationEnvironment1 = () => {
	const menuContents = [{ title: "입지안내", url: "/LocationEnvironment/intro" }, { title: "프리미엄", url: "/LocationEnvironment/primium" }];
	const [isScroll, setIsScroll] = useState(false);
	const { pathname } = useLocation(); // 현재 경로를 가져옴

	useEffect(() => {
		window.scrollTo(0, 0); // 페이지가 로드될 때 스크롤을 최상단으로 이동
	}, [pathname]); // pathname이 변경될 때마다 실행

	// 화면 스크롤이 탑이 아니면 isScroll 값 true로 변환
	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 0) {
				setIsScroll(true);
			} else {
				setIsScroll(false);
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<div className={styles.container}>

				<Helmet>
				<title>오산세교 힐데스하임 - 프리미엄</title>
				<meta name="description" content="고급 마감재와 세련된 디자인이 돋보이는 오산세교 힐데스하임은은 
						프리미엄 주거 공간을 제공하여 탁월한 건축 품질과 고급 시설이 조화롭게 어우러져 있는 장점을 확인하실 수 있습니다." />
				<meta property="og:title" content="오산세교 힐데스하임 - 프리미엄" />
				<meta property="og:description" content="고급 마감재와 세련된 디자인이 돋보이는 오산세교 힐데스하임은은 
						프리미엄 주거 공간을 제공하여 탁월한 건축 품질과 고급 시설이 조화롭게 어우러져 있는 장점을 확인하실 수 있습니다." />
				<meta property="og:image" content="https://www.hillstate-thefrist.com/mainImage.jpg" />
				<meta property="og:url" content="https://www.hillstate-thefrist.com/LocationEnvironment/primium" />
				<meta name="twitter:title" content="오산세교 힐데스하임 - 프리미엄" />
				<meta name="twitter:description" content="고급 마감재와 세련된 디자인이 돋보이는 오산세교 힐데스하임은은 
						프리미엄 주거 공간을 제공하여 탁월한 건축 품질과 고급 시설이 조화롭게 어우러져 있는 장점을 확인하실 수 있습니다." />
				<meta name="twitter:image" content="https://www.hillstate-thefrist.com/mainImage.jpg" />
				<meta name="twitter:url" content="https://www.hillstate-thefrist.com/LocationEnvironment/primium" />
				</Helmet>
			

			<Header isChanged={isScroll} />
			<FixIcon />

			<Bener title="입지환경" />

			<MenuBar contents={menuContents} />

			{/* <h1> 태그를 사용하여 페이지 제목 설정 (SEO 최적화) */}
			<h1 className={styles.screenReaderOnly}>오산세교 힐데스하임 - 프리미엄 </h1>
			<p className={styles.screenReaderOnly}>고급 마감재와 세련된 디자인이 돋보이는 오산세교 힐데스하임은은 
			프리미엄 주거 공간을 제공하여 탁월한 건축 품질과 고급 시설이 조화롭게 어우러져 있는 장점을 확인하실 수 있습니다.
			</p>

			<div className={styles.textBox}>
           			<div>오산과 동탄중심 세교지구의 눈부신 가치 위에</div>
           		 	<div>오산세교 힐데스하임의 새로운 자부심으로 찾아옵니다.</div>
         		</div>

			<img src={page1} className={styles.locationImg} alt="premium-image-1" />

			<div className={styles.commonBox}>	
				<div className={styles.notice}>
					※ 단지 주변 교통시설, 기타 주변 개발계획 등은 인·허가 및 정부 시책에 따라 변경 및 취소 가능하며 이는 사업주체 및 시공사와 무관합니다.
				</div>
			</div>

			<Footer />
		</div>
	)
}

export default LocationEnvironment1;
