import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styles from './ComplexGuide.module.scss';
import Header from "../../components/Header/Header";
import MenuBar from "../../components/MenuBar/MenuBar";
import Footer from "../../components/Footer/Footer";
import Bener from "../../components/Bener/Bener";
import FixIcon from "../../components/FixIcon/FixIcon";
import { Helmet } from "react-helmet-async";

import page1 from "../../assets/ComplexGuide/ComplexGuide1/01cont01.jpg";

const ComplexGuide1 = () => {
	const menuContents = [
		{ title: "단지 배치도", url: "/ComplexGuide/intro" },
		{ title: "호수 배치도", url: "/ComplexGuide/detailintro" },
		{ title: "커뮤니티", url: "/ComplexGuide/community" },
	];
	const [isScroll, setIsScroll] = useState(false);
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	useEffect(() => {
		const handleScroll = () => setIsScroll(window.scrollY > 0);
		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, []);

	return (
		<div className={styles.container}>
			<Helmet>
				<title>오산세교 힐데스하임 - 단지배치도</title>
				<meta name="description" content="오산세교 힐데스하임의 단지배치도를 통해 단지의 전체 구조와 배치를 확인하세요. 효율적인 공간 활용과 자연 친화적인 설계를 통해 쾌적한 단지 환경을 제공합니다." />
				<meta property="og:title" content="오산세교 힐데스하임 - 단지배치도" />
				<meta property="og:description" content="오산세교 힐데스하임의 단지배치도를 통해 단지의 전체 구조와 배치를 확인하세요. 효율적인 공간 활용과 자연 친화적인 설계를 통해 쾌적한 단지 환경을 제공합니다." />
				<meta property="og:image" content="https://www.hillstate-thefrist.com/mainImage.jpg" />
				<meta property="og:url" content="https://www.hillstate-thefrist.com/ComplexGuide/intro" />
				<meta name="twitter:title" content="오산세교 힐데스하임 - 단지배치도" />
				<meta name="twitter:description" content="오산세교 힐데스하임의 단지배치도를 통해 단지의 전체 구조와 배치를 확인하세요. 효율적인 공간 활용과 자연 친화적인 설계를 통해 쾌적한 단지 환경을 제공합니다." />
				<meta name="twitter:image" content="https://www.hillstate-thefrist.com/mainImage.jpg" />
				<meta name="twitter:url" content="https://www.hillstate-thefrist.com/ComplexGuide/intro" />
			</Helmet>

			<Header isChanged={isScroll} />
			<FixIcon />
			<Bener title="단지안내" />
			<MenuBar contents={menuContents} />

			<h1 className={styles.screenReaderOnly}>오산세교 힐데스하임 - 단지배치도</h1>
			<p className={styles.screenReaderOnly}>오산세교 힐데스하임의 단지배치도를 통해 단지의 전체 구조와 배치를 확인하세요. 효율적인 공간 활용과 자연 친화적인 설계를 통해 쾌적한 단지 환경을 제공합니다.</p>

			<div className={styles.textBox}>
			<div>오산세교 힐데스하임의 설계되는</div>
			<div>단지배치도를 확인하세요</div>
			</div>

			<img
				className={styles.complexGuideImage}
				src={page1}
				alt="Ohsan Segyo Hildesheim complex layout illustration"
			/>

			<div className={styles.commonBox}>
				{/* 안내 메시지 */}
				<div className={styles.notice}>
					※ 상기 단지배치도 이미지는 소비자의 이해를 돕기 위한 것으로 실제와 차이가 날 수 있습니다.
				</div>
				{/* 추가 메시지 생략 */}
			</div>

			<Footer />
		</div>
	);
};

export default ComplexGuide1;
