import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import styles from './SalesInfo.module.scss';
import Header from "../../components/Header/Header";
import MenuBar from "../../components/MenuBar/MenuBar";
import Footer from "../../components/Footer/Footer";
import Bener from "../../components/Bener/Bener";
import FixIcon from "../../components/FixIcon/FixIcon";
import { Helmet } from "react-helmet-async";

import page1 from "../../assets/SalesInfo/SalesInfoAnnouncement/03cont01.jpg";
import pdfIcon from "../../assets/icons/pdf-icon.png";  // PDF 아이콘 이미지 임포트

const ComplexGuide1 = () => {
    const menuContents = [
		{ title: "공급안내", url: "/SalesInfo/guide" },
		{ title: "모집공고", url: "/SalesInfo/announcement" },
		{ title: "인지세안내", url: "/SalesInfo/stampTax" },
		{ title: "당첨자구비서류", url: "/BusinessGuide/documents" },
		{ title: "이동통신협의결과서", url: "/SalesInfo/SubscriptionGuide" },
	];

    const [isScroll, setIsScroll] = useState(false);
    const [isImage2Loaded, setIsImage2Loaded] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // 로딩 상태 추가
    const { pathname } = useLocation();

    // 페이지 로드 시 상단으로 스크롤 이동
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    // 스크롤 이벤트로 헤더 상태 변경
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsScroll(true);
            } else {
                setIsScroll(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // 이미지 로드 후 애니메이션 실행
    const handleImageLoad = () => {
        setIsImage2Loaded(true);
    };

    // PDF 새 창으로 열기 함수
    const openPDF = () => {
        setIsLoading(true); // PDF 로딩 시작
        const pdfUrl = "/come01.pdf";  // 실제 PDF 파일 경로로 수정
        const newWindow = window.open(pdfUrl, "_blank");
        
        // PDF 로딩 후 로딩 상태 해제
        newWindow.onload = () => {
            setIsLoading(false);
        };
    };

    return (
        <div className={styles.container}>

		<Helmet>
		<title>오산세교 힐데스하임 - 모집공고</title>
		<meta name="description" content="오산세교 힐데스하임의 입주자 모집공고를 제공하여 성공적인 분양을 도와드립니다" />
		<meta property="og:title" content="오산세교 힐데스하임 - 모집공고" />
		<meta property="og:description" content="오산세교 힐데스하임의 입주자 모집공고를 제공하여 성공적인 분양을 도와드립니다" />
		<meta property="og:image" content="https://www.hillstate-thefrist.com/mainImage.jpg" />
		<meta property="og:url" content="https://www.hillstate-thefrist.com/SalesInfo/announcement" />
		<meta name="twitter:title" content="오산세교 힐데스하임 - 모집공고" />
		<meta name="twitter:description" content="오산세교 힐데스하임의 입주자 모집공고를 제공하여 성공적인 분양을 도와드립니다" />
		<meta name="twitter:image" content="https://www.hillstate-thefrist.com/mainImage.jpg" />
		<meta name="twitter:url" content="https://www.hillstate-thefrist.com/SalesInfo/announcement" />
		</Helmet>

            <Header isChanged={isScroll} />
            <FixIcon />

            <Bener title="모집공고" />

            <MenuBar contents={menuContents} />

			{/* <h1> 태그를 사용하여 페이지 제목 설정 (SEO 최적화) */}
			<h1 className={styles.screenReaderOnly}>오산세교 힐데스하임 - 모집공고 </h1>
			<p className={styles.screenReaderOnly}>오산세교 힐데스하임의 입주자 모집공고를 제공하여 성공적인 분양을 도와드립니다
			</p>

			


            <img
        className={`${styles.image2} ${isImage2Loaded ? styles.showImage2 : ''}`}
        src={page1}
        alt="03cont01-img"
        onLoad={handleImageLoad}  // 이미지 로드 후 애니메이션 실행
         />

		 {/* 입주자 모집공고 PDF로 보기 버튼 */}
		 <button onClick={openPDF} className={styles.pdfButton}>
                <img src={pdfIcon} alt="PDF 아이콘" className={styles.pdfIcon} /> {/* 이미지 추가 */}
                <span>모집공고 PDF<br/>확인하기</span>
            </button>

            


            <div className={styles.commonBox}>
                <div className={styles.notice}>
                    ※ 상기 내용은 편집과정상 오류가 있을 수 있으니 반드시 입주자모집공고를 확인하시기 바랍니다.
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default ComplexGuide1;
