import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import styles from './BusinessGuide.module.scss';
import Header from "../../components/Header/Header";
import MenuBar from "../../components/MenuBar/MenuBar";
import Footer from "../../components/Footer/Footer";
import Bener from "../../components/Bener/Bener";
import FixIcon from "../../components/FixIcon/FixIcon";
import { Helmet } from "react-helmet-async";


import page1 from "../../assets/BusinessGuide/BusinessGuide1/page1.jpg";
import tableImage from "../../assets/BusinessGuide/BusinessGuide1/tableImage.jpg";

const projectData = [
	{ label: '사업명', value: '오산세교 힐데스하임' },
	{ label: '사업위치', value: '오산세교2지구 A20BL' },
	{ label: '대지면적', value: '41,728.0000㎡' },
	{ label: '건축면적', value: '6,086,6541㎡' },
	{ label: '연면적', value: '112,298.0649㎡' },
	{ label: '3단지규모', value: '3단지: 지하 2층 ~ 지상 최고 25층 9개동 715세대' },

	{ label: '주차대수', value: '3단지:834대 (1.88대) ｜ 5단지:4,144대(2.53대)' },
];

const BusinessGuide1 = () => {
	const menuContents = [
		{ title: "사업안내", url: "/BusinessGuide/intro" },
		{ title: "분양일정", url: "/BusinessGuide/plan" },
		{ title: "계약서류안내", url: "/BusinessGuide/documents" }
	];
	const [isScroll, setIsScroll] = useState(false);
	const { pathname } = useLocation();
	const isMobile = useMediaQuery({ query: '(max-width: 900px)' });

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	useEffect(() => {
		const handleScroll = () => {
			setIsScroll(window.scrollY > 0);
		};

		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, []);

	return (
		<div className={styles.container}>
			<Helmet>
				<title>오산세교 힐데스하임 - 사업개요</title>
				<meta name="description" content="오산세교 힐데스하임의 사업개요를 통해 프로젝트의 전반적인 개요와 개발 비전을 확인하세요. 최신 건축 기술과 혁신적인 설계를 통해 쾌적한 생활 환경을 제공합니다." />
				<meta property="og:title" content="오산세교 힐데스하임 - 브랜드 소개" />
				<meta property="og:description" content="오산세교 힐데스하임의 사업개요를 통해 프로젝트의 전반적인 개요와 개발 비전을 확인하세요. 최신 건축 기술과 혁신적인 설계를 통해 쾌적한 생활 환경을 제공합니다." />
				<meta property="og:image" content="https://www.hillstate-thefrist.com/mainImage.jpg" />
				<meta property="og:url" content="https://www.hillstate-thefrist.com/BusinessGuide/intro" />
				<meta name="twitter:title" content="오산세교 힐데스하임 - 브랜드 소개" />
				<meta name="twitter:description" content="오산세교 힐데스하임의 사업개요를 통해 프로젝트의 전반적인 개요와 개발 비전을 확인하세요. 최신 건축 기술과 혁신적인 설계를 통해 쾌적한 생활 환경을 제공합니다." />
				<meta name="twitter:image" content="https://www.hillstate-thefrist.com/mainImage.jpg" />
				<meta name="twitter:url" content="https://www.hillstate-thefrist.com/BusinessGuide/intro" />
			</Helmet>
			

			<Header isChanged={isScroll} />
			<FixIcon />
			<Bener title="사업개요" />
			<MenuBar contents={menuContents} />

			<div className={styles.textBox}>
				<div>세교3지구의 중심에서 누리는 특별한 라이프 컬렉션</div>
				<div>오산세교 힐데스하임의 새로운 자부심으로 찾아옵니다.</div>
			</div>

			{/* <h1> 태그를 사용하여 페이지 제목 설정 (SEO 최적화) */}
						<h1 className={styles.screenReaderOnly}>오산세교 힐데스하임 - 사업안내</h1>
						<p className={styles.screenReaderOnly}>오산세교 힐데스하임의 사업개요를 통해 프로젝트의 전반적인 개요와 개발 비전을 확인하세요. 
							최신 건축 기술과 혁신적인 설계를 통해 쾌적한 생활 환경을 제공합니다.</p>
			
				<img className={styles.img1} src={page1} alt="오산세교 힐데스하임의 조감도이미지" />

			<div className={styles.tableContainer}>
				{!isMobile && <img className={styles.tableImg} src={tableImage} />}
				<table className={styles.projectTable}>
					<tbody>
						{projectData.map((item, index) => (
							<tr key={index}>
								<td className={styles.label}>{item.label}</td>
								<td className={styles.contents}>{item.value}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>


			<Footer />
		</div >
	);
};

export default BusinessGuide1;